.offices {
  .office-row {
    display: flex;
    padding-bottom: inRem(64);

    @include small(down) {
      flex-direction: column;
    }


    .left-col {
      width: 60%;
      @include medium(down) {
        width: 55%;
      }
      @include small(down) {
        width: 100%;
      }
    }
    .right-col {
      width: calc(40% - 32px);
      margin-left: 32px;

      @include medium(down) {
        margin-left: 0;
        width: calc(45% - 32px);
      }
      @include small(down) {
        width: 100%;
      }
    }
  }

  .info {
    display: flex;
    width: 100%;

    @include xsmall(down) {
      flex-direction: row;
      justify-content: center;
    }

    .image {
      width: inRem(80);
    }
    .data {
      margin-left: inRem(16);
      width: calc(100% - #{inRem(96)});
      @include xsmall(down) {
        margin-left: 0;
        width: auto;
      }
    }
  }

  .sub-title {

    @include small(down) {
      flex-direction: column;
    }

    h4 {
      @include fontLight(inRem(16));
      margin-right: inRem(16);
      @include small(down) {
        margin-right: 0;
      }
    }
    .phones {
      border-left: 2px solid $blueDark;
      position: relative;
      padding-left: inRem(16);
      @include small(down) {
        border-left: none;
        padding-left: 0;
        margin-top: inRem(16);
      }
    }
  }

  h3.office-title {
    @include fontBold(inRem(24));
    text-transform: uppercase;
    margin-bottom: inRem(12);
  }

  .office-map {
    width: inRem(360);
    height: inRem(260);
    cursor: pointer;
    @include medium(down) {
      width: 100%;
      height: inRem(320);
    }
  }

}
