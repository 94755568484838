.contact-links-row {

  .link-el {
    margin-bottom: inRem(8);
    a {
      @include fontRegular(inRem(18));
    }
  }

  &.emails-row {

  }

  &.phones-row {
    .link-el {
      a {
        @include fontSemiBold(inRem(18));
        text-decoration: none;
      }
    }
  }

  &.bigger {
    .link-el {
      margin-bottom: inRem(8);
      a {
        @include fontRegular(inRem(24));
      }
    }

    &.phones-row {
      .link-el {
        a {
          @include fontSemiBold(inRem(24));
          text-decoration: none;
        }
      }
    }
  }

}
