.beam-menu {
  position: fixed;
  left: 0;
  width: 100%;
  min-height: inRem($beam_menu_height);
  z-index: 200;

  @include medium(down) {
    position: static;
  }

  a{
    color: $white;
  }

  &.top-menu {
    top:0;
    background-color: $gray;

    @include medium(down) {
      display: none;
    }
  }

  &.bottom-menu {
    bottom:0;
    background-color: $grayLight;
  }

  ul {
    height: inRem($beam_menu_height);
    display: flex;
    align-items: center;
    li {
      display: flex;
      align-items: center;
      justify-content: center;
      a{
        text-transform: uppercase;
        text-decoration: none;
        @include fontRegular(inRem(14));
        padding-right: inRem(16);
        padding-left: inRem(16);

        @include problemQuery{
          @include fontRegular(inRem(13));
          padding-right: inRem(10);
          padding-left: inRem(10);
        }

        &:first-child {
          padding-left: 0;
        }
      }

      &.active {
        a{
          @include fontBold(inRem(14));
        }
      }

    }
  }

  .right-col {
    color: $white;
    @include fontRegular(inRem(13));
    .logos {
      img {
        width: inRem(60);
      }
    }
  }

  &.bottom-menu {
    @include medium(down) {
      padding-top: inRem(32);
      padding-bottom: inRem(32);
    }
    .container-left-space {
      @include medium(down) {
        flex-direction: column;
        .logos {
          margin-right: 0;
        }
      }


      @include small(down) {
        .copy {
          margin-bottom: inRem(16);
        }
        .right-col {
          flex-direction: column;
          .logos {
            flex-direction: column;
            >* {
              margin-bottom: inRem(16);
            }
          }
        }
        .left-col {
          flex-direction: column;
          ul {
            flex-direction: column;
            height: auto;
            li{
              margin-bottom: inRem(16);
              &:last-child {
                margin-bottom: 0;
              }
              a{
                padding-right: 0;
                padding-left: 0;
              }
            }
          }
        }
      }
    }
  }

}
