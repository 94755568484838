.press-office {

  @include xsmall {
    text-align: center;
  }

  h3.office-title {
    @include fontBold(inRem(24));
    text-transform: uppercase;
    margin-bottom: inRem(8);
  }
  h4.sub-title {
    @include fontRegular(inRem(16));
  }

  .office-map {
    width: 100%;
    height: inRem(360);
    cursor: pointer;
    margin-top: inRem(18);
    @include medium(down) {
      height: inRem(420);
    }
  }
}
