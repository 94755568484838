.investitions {
  .invest {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    box-shadow: 0 0 #{inRem(6)} $gray;

    @include xxsmall {
      grid-template-columns: repeat(1,1fr);
    }

    [style^='--aspect-ratio']::before {
      content: "";
      display: inline-block;
      width: 1px;
      height: 0;
      padding-bottom: calc(100% / (var(--aspect-ratio)));
    }
    .image {
      overflow: hidden;
      height: 100%;
      position: relative;
      img{
        position: absolute;
        left: 0;
        top:0;
        height: 100%;
        z-index: 2;
      }
      .info-label {
        position: absolute;
        left: 0;
        top:inRem(16);
        z-index: 3;
        color: $white;
        @include fontSemiBold(inRem(20));
      }
    }
    .data {
      position: relative;
      .content-in {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        img{
          display: block;
          margin: 2rem auto 1rem auto;
          height: 50%;
        }
        .more-invest {
          a{
            border: 2px solid $fontColor;
            color: $fontColor;
            @include fontBold(inRem(18));
            text-decoration: none;
            &:hover {
              &.more-with-color {
                text-decoration: none;
                color: $white;
              }
            }
            padding: inRem(8);

            @include problemQuery {
              margin: inRem(8);
              padding: inRem(8);
              @include fontBold(inRem(16));
            }

            @include medium {
              margin: inRem(8);
              padding: inRem(8);
              @include fontBold(inRem(16));
            }

            @include xsmall {
              margin: inRem(8);
              padding: inRem(8);
              @include fontBold(inRem(16));
            }

          }
        }
      }
    }
  }
  .invest-under {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    .left-col {

    }
    .right-col {

    }
  }

  span.work-state {
    @include fontBold(inRem(20));
    color: $gray;
  }

  h3.invest-name {
    @include fontBold(inRem(20));
    color: $gray;
  }
}
