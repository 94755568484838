.blog-article {
  @include small(down) {
    flex-direction: column;
  }
  .col-left {
    width: inRem(300);

    @include small(down) {
      width: 100%;
      margin-bottom: inRem(32);
    }
  }
  .col-right {
    margin-left: inRem(32);
    width: calc(100% - #{inRem(320)});

    @include small(down) {
      margin-left: 0;
      width: 100%;
    }
  }
}
