.contact-groups {

  .contact-group {
    //padding-bottom: inRem(64);
    padding-top: inRem(32);

    @include small(down) {
     padding-bottom: inRem(32);
    }

    button.button-send-contact {
      background-color: $white;
      @include fontSemiBold(inRem(18));
    }

    &.group-color-blue{
      border-bottom: 2px solid $blueDark;
      button.button-send-contact {
        border: 2px solid $blueDark;
        color: $blueDark;
        &:hover{
          color: $white;
          background-color: $blueDark;
        }
      }
    }

    &.group-color-pink{
      border-bottom: 2px solid $pink;
      button.button-send-contact {
        border: 2px solid $pink;
        color: $pink;
        &:hover{
          color: $white;
          background-color: $pink;
        }
      }
    }

    &.group-color-yellow{
      border-bottom: 2px solid $fontColor;
      button.button-send-contact {
        border: 2px solid $fontColor;
        color: $fontColor;
        &:hover{
          color: $white;
          background-color: $fontColor;
        }
      }
    }

    &.group-column {
      border-bottom: none;
      padding-bottom: 0;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  h3.group-title {
    @include fontRegular(inRem(32));
    margin-bottom: inRem(40);
  }
  .column.person-data {

    //@include xsmall(down) {
    //  text-align: center;
    //}

    @include small(down) {
      padding-bottom: inRem(32);
      &:last-child {
        padding-bottom: 0
      }
    }

    h4.person-name {
      @include fontBold(inRem(20));
      margin-bottom: inRem(8);
    }
    span.person-role {
      @include fontLight(inRem(16));
      margin-bottom: inRem(8);
    }

  }

}
