.notice {
  .row-notice {
    line-height: inRem(30);
    span.label {
      @include fontBold(inRem(20));
    }

    span.data {
      @include fontRegular(inRem(20));
    }

    &.description {
      @include fontRegular(inRem(20));
      padding-right: inRem(128);
      @include xsmall(down) {
        padding-right: 0;
      }
    }

    .link {
      a{
        @include fontRegular(inRem(20));
      }

      a.link-blue {
        color: $blueDark;
      }
      a.link-pink {
        color: $fontColor;
      }
      a.link-yellow {
        color: $fontColor;
      }
    }

  }
}
