.link-invest-site {
  height: inRem(60);
  flex-direction: row;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  span.wrap-on {
    height: inRem(60);
    width: inRem(38);
    justify-self: end;
  }

  span.link-text {
    height: inRem(60);
    display: flex;
    align-items: center;
    justify-content: center;
    @include fontRegular(inRem(20));

  }

  &.wrap-link-blue {
    span.link-text {
      background-color: $blue;
      color: $white;
    }
    span.wrap-on {
      background: url("../../img/new-site/wrap-right-blue.png") right top no-repeat;
      background-size: inRem(38);
    }
  }

  &.wrap-link-pink {
    span.link-text {
      background-color: $pink;
      color: $white;
    }
    span.wrap-on {
      background: url("../../img/new-site/wrap-right-pink.png") right top no-repeat;
      background-size: inRem(38);
    }
  }

  &.wrap-link-yellow {
    span.link-text {
      background-color: $yellow;
      color: $fontColor;
    }
    span.wrap-on {
      background: url("../../img/new-site/wrap-right-yellow.png") right top no-repeat;
      background-size: inRem(38);
    }
  }
}
