.context-menu-beam {
  position: fixed;
  left: 0;
  width: 100%;
  height: inRem($context_menu_height);
  z-index: 200;

  @include medium(down) {
    display: none;
  }

  .arrow-back-history {
    position: absolute;
    height: 100%;
    top: 0;
    left: inRem(20);

    img{
      height: inRem(35);
    }
  }

  .menu-content {
    .logo-left{
      img {
        max-width: inRem(234);
      }
    }
  }

  &__top{
    top: inRem(40);

    //&.less-then-3 {
    //  li{
    //    width: inRem(200);
    //  }
    //}
  }

  &__bottom{
    bottom: inRem(40);
    width: calc(100% - (#{inRem($wrap_context_beam_width)}));
    .wrap-abs{
      position: absolute;
      left: 100%;
      top: 0;
      width: inRem($wrap_context_beam_width);
      height: 100%;

      &.wrap-blue {
        background: url("../../img/new-site/wrap-right-blue.png") no-repeat left top;
        background-size: auto inRem($context_menu_height);
      }

      &.wrap-yellow {
        background: url("../../img/new-site/wrap-right-yellow.png") no-repeat left top;
        background-size: auto inRem($context_menu_height);
      }

      &.wrap-pink {
        background: url("../../img/new-site/wrap-right-pink.png") no-repeat left top;
        background-size: auto inRem($context_menu_height);
      }
    }

  }

  .links {
    width: 100%;
    ul{
      width: 100%;
      margin-left: inRem(25);
      li {
        position: relative;
        //width: 100%;
        width: inRem(230);
        padding-left: inRem(15);
        padding-right: inRem(15);
        display: flex;
        align-items: center;

        @include problemQuery {
          width: inRem(180);
        }

        &:last-child {
          //padding-right: 0;
        }

        a{
          @include fontRegular(inRem(16));
          text-transform: uppercase;
          line-height: inRem(20);
          text-decoration: none;

          &:hover {
            text-decoration: none;
            color: $white;
          }
        }

        &.active {
          a {
            @include fontBold(inRem(16));
          }
        }

        &:before {
          content: '';
          display: block;
          width: 2px;
          height: inRem(50);
          background-color: $fontColor;
          margin-right: inRem(15);
        }

        &:last-child {
          &:after {
            content: '';
            display: block;
            width: 2px;
            height: inRem(50);
            position: absolute;
            background-color: $fontColor;
            right: 0;
            top: 0;
          }
        }

      }

      &.more-then-3 {
        li{
          width: auto;
          padding-left: inRem(35);
          padding-right: inRem(35);
        }
      }

    }

    &.link-on-color-pink, &.link-on-color-blue{
      ul{
        li{
          &:before {
            background-color: $white;
          }
          &:last-child {
            &:after {
              background-color: $white;
            }
          }
        }
      }
    }

  }

}
