.breadcrumbs {
  min-height: inRem(50);
  line-height: inRem(26);
  ul{
    li {
      @include fontBold(inRem(16));
      text-transform: uppercase;
      a {
        @include fontRegular(inRem(16));
        text-transform: uppercase;
      }
      &:after {
        content: "/";
        display: block;
        padding-left: inRem(8);
        padding-right: inRem(8);
        @include fontRegular(inRem(16));
      }
      &:last-child {
        &:after {
          content: "";
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
}
