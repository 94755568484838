.overlayer#modalOverlayer{
  display: none;
  opacity: 0;
  &.visible {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.6);
    z-index: 998;
    display: block;
    opacity: 1;
    transition: opacity 700ms;
  }
}
