.articles-blog-rows {
  display: flex;
  flex-direction: column;
  .article-row {
    display: flex;
    flex-direction: row;
    padding-bottom: inRem(20);
    margin-bottom: inRem(36);
    border-bottom: 1px solid $gray;

    @include xsmall(down) {
      flex-direction: column;
    }

    .article-image {
      width: inRem(360);
      padding-bottom: inRem(16);
      img{
        width: inRem(360);
      }

      @include xsmall(down) {
        width: 100%;
        img {
          width: 100%;
        }
      }

    }

    .article-intro {

      @include xsmall(down) {
        padding-left: 0;
      }

      .article-data-info {
        span.article-date {
        }

        h2.article-title {
          margin-bottom: inRem(18);

          a {
            @include fontBold(inRem(26));
            text-decoration: none;
          }
        }

        .article-text {

        }
      }
    }
    .article-more {
      a{
        @include fontBold(30);
      }
    }
  }
}
