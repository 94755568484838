.custom-table {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1px;
  background-color: $fontColor;
  border: 1px solid $fontColor;

  @include smallMedium(down) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include xxsmall {
    grid-template-columns: repeat(1, 1fr);
  }

  >.tc{
    background-color: $white;
    min-height: inRem(90);
    padding: inRem(10) inRem(20);
    display: flex;
    justify-content: center;
    flex-direction: column;

    @include smallMedium(down) {
      align-items: flex-start;
    }

    &.tc-1 {
      .info {
        @include fontRegular(inRem(18));
        text-transform: uppercase;
      }
    }

    &.tc-4 {
      align-items: flex-start;

      .icon {
        width: inRem(25);
      }

      a {
        color: $fontColor;
        text-decoration: none;
        @include fontRegular(inRem(18));

        @include xxsmall {
          font-size: inRem(16);
        }

        &:hover {
          &.hover-pink {
            color: $pink;
          }
          &.hover-yellow {
            color: $yellow;
          }
          &.hover-blue {
            color: $blue;
          }
        }
      }
    }

    @include smallMedium(down) {
      &.tc-3, &.tc-4 {
        border-bottom: 2px solid $fontColor;
      }
    }

    @include xxsmall {
      &.tc-3 {
        border-bottom: none;
      }
      &.tc-4 {
        border-bottom: 2px solid $fontColor;
      }
    }

  }
}
