.about-article {
  .images-about {
    @include xxsmall {
      flex-direction: column;
    }
    img {
      width: inRem(160);
      @include xxsmall {
        width: auto;
        max-width: 100%;
      }
    }
  }
  .under-image {
    img {
      width: inRem(300);
    }
    &.arrows {
      img {
        width: 90%;
      }
    }
  }
}
