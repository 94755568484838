.top-menu {
  .container-small {
    .left-top-nav {
      width: 72%;
      @include problemQuery{
        width: 77%;
      }
    }
    .right-top-nav{
      width: 28%;
      justify-content: flex-end;
      @include problemQuery{
        width: 21%;
      }
    }
  }
}
