.pagination {
  margin-top: inRem(32);
  margin-bottom: inRem(24);
  ul.pagination-ul{
    display: flex;
    align-items: center;
    justify-content: center;
    li {
      padding: inRem(4);
      &.active{
        a {
          @include fontBold();
        }
      }
    }
  }
}
