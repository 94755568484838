.mobile-menu {
  position: fixed;
  left: 0;
  top:0;
  z-index: 200;
  width: 100%;

  @include medium(under) {
    display: none;
  }

  @include xxsmall(landscape) {
    position: absolute;
  }

  .menu-m-top {
    width: 90%;
    height: inRem($mobile_menu_height);
    margin: auto;
    .image-logo {
      height: inRem($mobile_menu_height);
      width: inRem(220);
    }
    .info-right {
      width: calc(100% - #{inRem(220)});

      .icons-links {

        @include xxsmall {
          display: none;
        }

        a {
          margin-right: inRem(18);
          img {
            width: inRem(30);
          }
        }
      }

      .burger {
        position: relative;
        width: inRem(46);
        .line {
          height: inRem(4);
          margin-top: inRem(5);
          background-color: #FFFFFF;

          &:first-child {
            margin-top: 0;
          }
        }

        a {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
        }
      }

    }
  }

  .menu-m-content {
    width: 90%;
    margin: auto;

    .menu-content {
      ul {
        li {
          margin-bottom: inRem(8);
          a {
            text-decoration: none;
            @include fontRegular(inRem(18));
            text-transform: uppercase;
          }
        }
      }

      .icons-links {
        display: none;

        @include xxsmall {
          display: flex;
        }

        a {
          margin-right: inRem(18);
          img {
            width: inRem(30);
          }
        }
      }

    }
  }
}
