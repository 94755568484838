$beamHeight: 44;
$wrapWidth: (58 * $beamHeight) / 74;
.right-links {
  position: fixed;
  right: 0;
  top:20vh;
  z-index: 104;
  width: inRem(220);

  @include medium(down) {
    display: none;
  }

  .right-link {
    position: relative;
    height: inRem($beamHeight);

    .beam-wrap {
      height: inRem($beamHeight);
      width: inRem($wrapWidth);

      &.wrap-left-blue {
        background: url("../../img/new-site/wrap-left-min-blue.png") top left;
        background-size: cover;
      }

      &.wrap-left-yellow {
        background: url("../../img/new-site/wrap-left-min-yellow.png") top left;
        background-size: #{inRem($wrapWidth)} #{inRem($beamHeight)};
      }

      &.wrap-left-pink {
        background: url("../../img/new-site/wrap-left-min-pink.png") top left;
        background-size: #{inRem($wrapWidth)} #{inRem($beamHeight)};
      }
    }

    .beam-icon {
      height: inRem($beamHeight);
      width: inRem(70);
      img {
        height: inRem($beamHeight - 18);
      }
    }

    .beam-content {
      height: inRem($beamHeight);
      text-transform: uppercase;
      @include fontRegular(inRem(16));
    }

    a{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
    }
  }
}
