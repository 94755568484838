.contact-form {
  display: none;
  &.visible {
    display: flex;
  }
  position: fixed;
  top: inRem(150);
  z-index: 999;
  width: inRem(600);
  padding: inRem(32) inRem(48);
  left: calc((100% - #{inRem(600)}) / 2);
  background-color: $white;

  @media (max-width: 768px) {
    position: absolute;
    top: inRem(80);
    width: 86vw;
    left: 7vw;
  }

  .close-btn {
    position: absolute;
    right: inRem(12);
    top: inRem(12);
    z-index: 101;
    img {
      width: inRem(20);
    }
  }

  .raport {
    display: none;
    &.visible {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 99;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(255,255,255,0.7);
      h4 {
        color: $blue;
        @include fontRegular(1.2rem);
      }
    }
  }

  .row.form-title {
    .col {
      h3 {
        @include fontBold(inRem(20));
        color: $blueDark;
        margin-bottom: inRem(12);
        @include problemQuery {
          @include fontBold(inRem(18));
          margin-bottom: inRem(6);
        }
      }
      h4 {
        @include fontRegular(inRem(18));
        color: $grayLight;
        margin-bottom: inRem(16);
        @include problemQuery {
          @include fontLight(inRem(12));
          margin-bottom: inRem(6);
        }
      }
    }
  }

  .row-form-control {
    display: flex;
    margin-bottom: inRem(8);
    &.one {
    }
    &.double {
      justify-content: space-between;
      input {
        width: calc((100% - #{inRem(8)}) / 2);
      }
      @include xxsmall {
        flex-direction: column;
        width: 100%;
        input {
          width: 100%;
          margin-bottom: inRem(8);
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .row-form-control {
    input,
    textarea {
      @include fontRegular(1rem);
      color: #000000;
      padding: inRem(10) inRem(16) inRem(10) inRem(16);

      @include problemQuery {
        padding: inRem(4) inRem(10) inRem(4) inRem(10);
      }

      &.is-invalid {
        border: 1px solid #ff0000 !important;
      }
    }

    textarea {
      height: inRem(87);
      @include problemQuery {
        height: inRem(60);
      }
    }

    input::-webkit-input-placeholder {
      font-size: inRem(14);
      letter-spacing: inRem(1);
      color: $grayLight;
      height: inRem(48);
    }

    input:-moz-placeholder {
      font-size: inRem(14);
      color: $grayLight;
      height: inRem(48);
    }

    input::-moz-placeholder {
      font-size: inRem(14);
      color: $grayLight;
      height: inRem(48);
    }

    input:-ms-input-placeholder {
      font-size: inRem(14);
      color: $grayLight;
      height: inRem(48);
    }

    textarea::-webkit-input-placeholder {
      font-size: inRem(14);
      color: $grayLight;
      height: inRem(48);
    }

    textarea:-moz-placeholder {
      font-size: inRem(14);
      color: $grayLight;
      height: inRem(48);
    }

    textarea::-moz-placeholder {
      font-size: inRem(14);
      color: $grayLight;
      height: inRem(48);
    }

    textarea:-ms-input-placeholder {
      font-size: inRem(14);
      color: $grayLight;
      height: inRem(48);
    }
  }

  .spacer {
    height: inRem(8);
  }
  
  .row-check-box {
    margin-bottom: inRem(8);
    label {
      display: flex;
      span.checkbox-squre {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: inRem(20);
        width: inRem(20);
        //border: 2px solid $blueDark;
        background-color: #ffffff;
        margin-right: inRem(8);
        span.select-in {
          height: inRem(10);
          width: inRem(10);
          //background-color: $blueDark;
        }
        span.over {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 33;
          cursor: pointer;
        }
      }
      span.info {
        width: calc(100% - #{inRem(24)});
        @include fontRegular(inRem(11));
        line-height: inRem(13);
        color: $grayLight;
        @include problemQuery {
          @include fontRegular(inRem(10));
          line-height: inRem(11);
        }
        span.more {
          color: $blueDark;
          @include fontBold(inRem(9));
        }
        span.less {
          color: $blueDark;
          @include fontBold(inRem(9));
        }
      }
    }
    &.not-selected {
      label {
        span.checkbox-squre {
          border-color: #ff0000;
        }
        span.info {
          color: #ff0000;
        }
      }
    }
  }
  .row-desc {
    margin-top: inRem(16);
    @include problemQuery {
      margin-top: inRem(8);
    }
    .col {
      @include fontRegular(inRem(9));
      color: $grayLight;
    }
  }
  .row-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: inRem(16);
    margin-bottom: inRem(8);
    @include problemQuery {
      margin-top: inRem(8);
      margin-bottom: inRem(4);
    }
    .col {
      button {
        width: inRem(140);
        height: inRem(50);
        border: 2px solid #ffffff;
        @include fontRegular(inRem(18));
        &:focus {
          outline: none;
        }
        @include problemQuery {
          @include fontRegular(inRem(16));
          height: inRem(32);
        }
      }
    }
  }


  &.contact-form-blue {
    .row.form-title {
      .col {
        h3{
          color: $blueDark;
        }
      }
    }
    .row-form-control {
      input, textarea {
        border: 1px solid $blue;
      }
    }
    .row-check-box {
      label {
        span.checkbox-squre {
          border: 2px solid $blueDark;
          span.select-in {
            background-color: $blueDark;
          }
        }
      }
    }
    .row-btn {
      .col {
        button {
          background-color: $blue;
          color: $white;
          &:hover {
            background-color: $blueDark;
          }
        }
      }
    }
  }

  &.contact-form-yellow {
    .row.form-title {
      .col {
        h3{
          color: $fontColor;
        }
      }
    }
    .row-form-control {
      input, textarea {
        border: 1px solid $grayLight;
      }
    }
    .row-check-box {
      label {
        span.checkbox-squre {
          border: 2px solid $fontColor;
          span.select-in {
            background-color: $fontColor;
          }
        }
      }
    }
    .row-btn {
      .col {
        button {
          background-color: $yellow;
          color: $fontColor;
          &:hover {
            background-color: $yellow;
          }
        }
      }
    }
  }

  &.contact-form-pink {
    .row.form-title {
      .col {
        h3{
          color: $pink;
        }
      }
    }
    .row-form-control {
      input, textarea {
        border: 1px solid $pink;
      }
    }
    .row-check-box {
      label {
        span.checkbox-squre {
          border: 2px solid $pink;
          span.select-in {
            background-color: $pink;
          }
        }
      }
    }
    .row-btn {
      .col {
        button {
          background-color: $pink;
          color: $white;
          &:hover {
            background-color: $pink;
          }
        }
      }
    }
  }

}
