.cookie-cloud {
  position: fixed;
  right: 0;
  bottom: 0;
  width: inRem(600);
  z-index: 300;
  padding: inRem(10) inRem(20);
  background-color: $white;
  box-shadow: 0 0 #{inRem(30)} $gray;
  color: $black;

  @include xsmall(down) {
    width: 100%;
  }

  h4 {
    @include fontBold(inRem(16));
  }
  .cookie-content {
    @include fontRegular(inRem(12));
    line-height: inRem(16);
  }

  .cookie-btn-beam {
    button {
      border: none;
      background: none;
      @include fontBold(inRem(14));
      color: $black;
    }
  }

  a{

  }
}
