.page-rotor {
  position: relative;
  margin-top: $beam_menu_height;
  height: calc(100vh - (#{inRem($beam_menu_height)} * 2));
  background-color: $white;

  &.full-crop {
    height: calc(100vh - (#{inRem((($beam_menu_height * 2) + $context_menu_height))}));
  }

  &.top-crop {

  }

  &.bottom-crop {

  }

  @include medium(down) {
    height: calc(100vh - #{inRem($mobile_menu_height)});
  }

  .image-title{
    position: absolute;
    left: inRem(30);
    bottom: inRem(110);
    z-index: 100;
    width: 26vw;
    @include small(down) {
      width: 30vw;
    }
    @include xsmall(down) {
      bottom: inRem(40);
    }
  }

  .slide {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 98;
    transition: opacity 1000ms;
  }
  .slide.active{
    opacity: 1;
    z-index: 99;
    transition: opacity 2000ms;
  }

  .slide-logo {
    position: absolute;
    right: inRem(60);
    bottom: inRem(100);
    width: inRem(270);

    @include medium(down) {
      right: inRem(40);
      bottom: inRem(40);
      width: inRem(270);
    }

    @include small(down) {
      right: inRem(50);
      bottom: inRem(50);
      width: inRem(210);
    }

    @include xsmall(down) {
      right: inRem(30);
      bottom: inRem(30);
      width: inRem(200);
    }

    @include xxsmall {
      right: inRem(30);
      bottom: inRem(30);
      width: inRem(140);
    }

    opacity: 0;
    z-index: 100;
    transition: opacity 1200ms;
  }

  .slide-logo.active {
    opacity: 1;
    z-index: 101;
    transition: opacity 2000ms;
  }
}

