.info-block {
  .label {
    width: 40%;
    @include fontRegular();
  }
  .content {
    width: calc(60% - #{inRem(32)});
    margin-left: inRem(32);
    @include fontBold();
  }
}
