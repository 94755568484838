ul.left-pages {
  padding-right: inRem(32);
  text-align: right;
  display: flex;
  flex-direction: column;

  @include problemQuery {
    padding-right: inRem(24);
  }

  @media (max-width: 1300px) and (min-width: 1200px) {
    padding-right: inRem(16);
  }

  li{
    margin-bottom: inRem(10);
    a{
      @include fontRegular(inRem(16));
      text-decoration: none;
      text-transform: uppercase;
      line-height: inRem(20);

      @media (max-width: 1300px) and (min-width: 1200px) {
        @include fontRegular(inRem(14));
      }

      &:hover {
        text-decoration: none;
      }
    }
    &.active{
      a {
        @include fontBold(inRem(16));
      }
    }
  }
}
