.popup {
  &.before-load{
    opacity:0;
  }

  &.after-load{
    opacity:1;
    transition: 700ms all;
    -webkit-transition: 700ms all;
    -moz-transition: 700ms all;
    -o-transition: 700ms all;
  }

  .overload-popup-base {
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;

    &.hidden-pop-up {
      display: none;
    }
  }

  .pop-up-content-base {
    position: fixed;
    display: flex;
    align-items: center;
    left: auto;
    top: 0vh;
    z-index: 2001;

    &.hidden-pop-up {
      top: -100vh;
      display: none;
      z-index: -1;
    }

    &.vertical {
      .inside-popup {
        img.image-popup {
          height: 70vh;
        }
      }
    }

    &.horizontal {
      .inside-popup {
        img.image-popup {
          width: 50vw;
          @include small(down){
            width: 70vw;
          }
        }
      }
    }

    .inside-popup {
      position: relative;

      button {
        background: #FFF;
        border: none;
        height: 30px;
        width: 30px;
        border-radius: 40px;
        font-size: 1.6rem;
        position: absolute;
        right: -15px;
        top: -15px;
        color: #000;
      }
    }
  }
}
