.g-elements-container {
  position: relative;
  width: $default_container;


  @include xxsmall {
    width: 80%;
  }

  @include xxxsmall {
    width: 70%;
  }

  @include large{
    width: $large_container;
  }

  @include problemQuery{
    width: $problem_container;
  }


  @include xlarge{
    width: $xlarge_container;
  }

  @include xxlarge{
    width: $xxlarge_container;
  }
  .navi {
    position: absolute;
    top: 0;
    height: 100%;
    width: inRem(72);
    display: flex;
    align-items: center;
    z-index: 33;
    &.prev {
      left: inRem(72, minus);
      @include xsmall {
        left: inRem(60, minus);
      }
      @include xxsmall {
        left: inRem(60, minus);
      }
    }
    &.next {
      right: inRem(72, minus);
      justify-content: flex-end;
      @include xsmall {
        right: inRem(60, minus);
      }
      @include xxsmall {
        right: inRem(60, minus);
      }
    }
    span.navi-bt {
      width: inRem(64);
      height: inRem(64);
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ffffff;
      cursor: pointer;

      @include xsmall {
        width: inRem(32);
        height: inRem(32);
      }

      @include xxsmall {
        width: inRem(32);
        height: inRem(32);
      }

      img {
        max-width: 80%;
      }
      &.prev-bt {
      }

      &.next-bt {

      }
      &.disable {
        opacity: 0.3;
        filter: grayscale(100%);
        -webkit-filter: grayscale(100%);
      }
    }
  }
}

#g-elementsRotor {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;

  .g-element-slide{
    overflow: hidden;
    height: inRem(230);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    @include xxsmall {
      height: inRem(170);
    }

    @include xxxsmall {
      height: inRem(140);
    }

    .gallery-title {
      position: absolute;
      left: 0;
      top:0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      @include fontSemiBold(inRem(24));
      color: #FFFFFF;
      box-shadow: 0 0 #{inRem(18)} $black;
    }
    .el-play {
      position: absolute;
      left: 0;
      top:0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        width: inRem(64);
      }
    }
  }

  .tor {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  img.gallery-r-element{
    max-width: none;
    width: 140%;
    &.lazy {
      width: 60%;
    }
    @media (max-width: 1160px) {
      max-width: 140%;
      height: auto;
      &.lazy {
        width: 60%;
      }
    }
  }
}
