.notice-list {
  padding-bottom: inRem(32);
  .more-bt {
    position: relative;
    height: inRem($beamHeight);
    a {
      position: absolute;
      left: 0;
      top:0;
      width: 100%;
      height: 100%;
    }
    .wrap-right{
      height: inRem($beamHeight);
      width: inRem($wrapWidth);

      &.wrap-blue {
        background: url("../../img/new-site/wrap-left-min-blue.png") top left;
        background-size: cover;
      }
      &.wrap-pink {
        background: url("../../img/new-site/wrap-left-min-pink.png") top left;
        background-size: cover;
      }
      &.wrap-yellow {
        background: url("../../img/new-site/wrap-left-min-yellow.png") top left;
        background-size: cover;
      }
    }
    span {
      height: inRem($beamHeight);
      text-transform: uppercase;
      @include fontRegular(inRem(16));
      line-height: inRem(18);
      width: inRem(220);

      @include xsmall(down) {
        width: 100%;
      }

      &.span-blue {
        background-color: $blue;
        color: $white;
      }
      &.span-pink {
        background-color: $pink;
        color: $white;
      }
      &.span-yellow {
        background-color: $yellow;
        color: $fontColor;
      }
    }
  }
}
